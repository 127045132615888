<template>
  <div
    class="columns is-mobile is-multiline"
  >
    <TextInput
      v-model="filters.email"
      class="column is-full-mobile is-2-tablet pl-1 pr-1 mb-0"
      label="Email"
      name="email"
    />
    <TextInput
      v-model="filters.bankName"
      class="column is-half-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Bank name"
      name="bank-name"
    />
    <TextInput
      v-model="filters.accountNumber"
      class="column is-half-mobile is-4-tablet pl-1 pr-1 mb-0"
      label="Account number"
      name="account-number"
    />
    <StatusList
      v-model:status="filters.status"
      class="column is-half-mobile is-2-tablet pl-1 pr-1 mb-0"
      label="Status"
    />
    <TextInput
      v-model="filters.transactionId"
      :class="[shouldDisplayNonceFilter ? 'is-2-tablet' : 'is-4-tablet']"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="Transaction ID"
      name="transaction-id"
    />
    <TextInput
      v-if="shouldDisplayNonceFilter"
      v-model="filters.nonce"
      class="column is-full-mobile is-2-tablet pl-1 pr-1 mb-0"
      label="Nonce"
      name="nonce"
    />
    <TextInput
      v-if="!shouldShowMerchantSelectFilter"
      v-model="filters.merchantId"
      :class="[shouldDisplayCreation ? 'is-2-tablet' : 'is-4-tablet']"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="Merchant ID"
      name="merchant-id"
    />
    <MerchantListFilter
      v-else-if="shouldShowMerchantSelectFilter"
      v-model:status="filters.merchantId"
      :class="[shouldDisplayCreation ? 'is-2-tablet' : 'is-4-tablet']"
      class="column is-full-mobile pl-1 pr-1 mb-0"
      label="Merchant ID"
      :show-all-option="true"
    />
    <TextInput
      v-model="filters.currency"
      :class="getCurrencyClass()"
      label="Currency"
      name="currency"
    />
    <CreationList
      v-if="shouldDisplayCreation"
      v-model:status="filters.creation"
      class="column is-half-mobile is-2-tablet pl-1 pr-1 mb-0"
      label="creation"
    />
    <TextInput
      v-if="shouldDisplaySourceBankName"
      v-model="filters.sourceBankName"
      class="column is-full-mobile is-2-tablet pl-1 pr-1 mb-0"
      label="Source bank name"
      name="source-bank-name"
    />
    <TextInput
      v-model="filters.receivedBy"
      class="column is-full-mobile is-full-tablet pl-1 pr-1 mb-0"
      label="Operator"
      name="received-by"
    />
    <TextInput
      v-model="filters.firstName"
      class="column is-full-mobile is-half-tablet pl-1 pr-1 mb-0"
      label="First Name"
      name="first-name"
    />
    <TextInput
      v-model="filters.surname"
      class="column is-full-mobile is-half-tablet pl-1 pr-1 mb-0"
      label="Surname"
      name="surname"
    />
    <CalendarInput
      v-model:startDate="filters.dateFrom"
      v-model:endDate="filters.dateTo"
      class="column is-full-mobile is-half-tablet pl-1 pr-1 mb-0"
      label="Creation Date"
    />
    <CalendarInput
      v-if="shouldDisplayReceiptCalendar"
      v-model:startDate="filters.receiptDateFrom"
      v-model:endDate="filters.receiptDateTo"
      class="column is-full-mobile is-half-tablet pl-1 pr-1 mb-0"
      label="Receipt Date"
    />
    <TextInput
      v-model="filters.amountFrom"
      class="column is-full-mobile is-3-tablet pl-1 pr-1 mb-0"
      label="Settled Amount From"
      name="settled-amount-from"
    />
    <TextInput
      v-model="filters.amountTo"
      class="column is-full-mobile is-3-tablet pl-1 pr-1 mb-0"
      label="Settled Amount To"
      name="settled-amount-to"
    />
    <TextInput
      v-model="filters.authorizedAmountFrom"
      class="column is-full-mobile is-3-tablet pl-1 pr-1 mb-0"
      label="Authorized Amount From"
      name="authorized-amount-from"
    />
    <TextInput
      v-model="filters.authorizedAmountTo"
      class="column is-full-mobile is-3-tablet pl-1 pr-1 mb-0"
      label="Authorized Amount To"
      name="authorized-amount-to"
    />
  </div>
</template>

<script>
import { inject } from 'vue';
import CalendarInput from '../common/CalendarInput.vue';
import TextInput from '../common/TextInput.vue';
import StatusList from './StatusList.vue';
import MerchantListFilter from './MerchantListFilter.vue';
import CreationList from './CreationList.vue';
import initializeFlagsmith from '../flagsmith';

export default {
    name: 'FilterForm',
    components: {
        CalendarInput,
        TextInput,
        StatusList,
        CreationList,
        MerchantListFilter,
    },

    async setup() {
        const { filters } = inject('btb_transactions');

        const flagsmith = await initializeFlagsmith();
        const shouldDisplaySourceBankName = flagsmith.hasFeature('btb_source_bank');
        const shouldDisplayCreation = flagsmith.hasFeature('creation_status_btb');
        const shouldDisplayNonceFilter = flagsmith.hasFeature('nonce_filter_all_panels');
        const shouldShowMerchantSelectFilter = flagsmith.hasFeature('show_merchant_select');
        const shouldDisplayReceiptCalendar = flagsmith.hasFeature('receipt_date_calendar');

        const getCurrencyClass = () => {
            if (shouldDisplaySourceBankName) {
                return 'column is-full-mobile is-2-tablet pl-1 pr-1 mb-0';
            }

            return 'column is-full-mobile is-4-tablet pl-1 pr-1 mb-0';
        };

        return {
            filters,
            shouldDisplaySourceBankName,
            getCurrencyClass,
            shouldDisplayCreation,
            shouldDisplayNonceFilter,
            shouldShowMerchantSelectFilter,
            shouldDisplayReceiptCalendar,
        };
    },
};
</script>
