<template>
  <div>
    <div class="select is-fullwidth">
      <div class="control is-expanded">
        <select
          @change="$emit('update:status', $event.target.value)"
        >
          <option
            value=""
            selected
          >
            All
          </option>
          <option value="AUTO">
            Auto
          </option>
          <option value="MANUAL">
            Manual
          </option>
          <option value="MODIFIED">
            Modified
          </option>
          <option value="REFUND">
            Refund
          </option>
          <option value="SYSTEM">
            System
          </option>
          <option value="TRANSFER BACK">
            Transfer Back
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import initializeFlagsmith from '../flagsmith';

export default {
    emits: ['update:status'],

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const shouldDisplayCaptchaStatus = flagsmith.hasFeature('use_captcha');

        return {
            shouldDisplayCaptchaStatus,
        };
    },
};
</script>
