<template>
  <button
    class="button is-small is-primary"
    title="Add new transaction"
    @click="openFirstModal"
  >
    Add Manual Transaction
  </button>

  <div
    class="modal"
    :class="{'is-active': isFirstModalOpen}"
  >
    <div
      class="modal-background"
      @click="closeModal"
    />
    <div class="modal-content">
      <div class="box">
        <div class="field is-half column">
          <label class="label">Merchant ID</label>
          <div class="control">
            <MerchantList
              ref="selectedId"
              label="Merchant ID"
              @change="setMerchantId"
            />
          </div>
        </div>
        <div class="field is-grouped is-grouped-right">
          <p class="control">
            <button
              class="button is-primary"
              :class="{ 'is-loading': isLoading }"
              :disabled="
                isFirstSaveButtonActive()"
              @click="openSecondModal"
            >
              Save
            </button>
          </p>
          <p class="control">
            <a
              class="button is-light"
              @click="closeModal"
            >
              Cancel
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal"
    :class="{'is-active': isSecondModalOpen}"
  >
    <div
      class="modal-background"
      @click="closeModal"
    />
    <div class="modal-content">
      <div class="box">
        <div
          v-if="showNotification"
          class="notification is-danger"
        >
          {{ notificationContent }}
        </div>
        <div class="is-flex is-flex-wrap-wrap columns">
          <div class="field is-half column">
            <label class="label">First Name</label>
            <div class="control">
              <input
                v-model="newTransaction.name"
                class="input"
                type="text"
                placeholder="Enter First Name"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Surname</label>
            <div class="control">
              <input
                v-model="newTransaction.surname"
                class="input"
                type="text"
                placeholder="Enter Surname"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Email</label>
            <div class="control">
              <input
                v-model="newTransaction.email"
                class="input"
                type="email"
                placeholder="Enter Email"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Authorized Amount</label>
            <div class="control">
              <input
                v-model="newTransaction.authorizedAmount"
                class="input"
                type="text"
                readonly
                disabled
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Settled Amount</label>
            <div class="control">
              <input
                v-if="shouldShowMaskedAmountInputs"
                ref="settledAmountRef"
                class="input"
                type="text"
                placeholder="Enter Settled Amount"
                @keyup="setAuthorizedAmountWithDebounce"
              >
              <input
                v-else
                v-model="newTransaction.settledAmount"
                class="input"
                type="number"
                min="0"
                placeholder="Enter Settled Amount"
              >
            </div>
          </div>
          <div class="field is-half column">
            <label class="label">Merchant ID</label>
            <div class="control">
              <MerchantList
                ref="selectedIdSecond"
                label="Merchant ID"
                :is-disabled="true"
              />
            </div>
          </div>
          <div class="field is-full column">
            <label class="label">Nonce</label>
            <div class="control">
              <input
                v-model="newTransaction.nonce"
                class="input"
                type="text"
                placeholder="Enter nonce"
              >
            </div>
          </div>
        </div>
        <div class="field is-grouped is-grouped-right">
          <p class="control">
            <button
              class="button is-primary"
              :disabled="isSaveButtonActive()"
              :class="{ 'is-loading': isLoading }"
              @click="addNewTransaction"
            >
              Save
            </button>
          </p>
          <p class="control">
            <a
              class="button is-light"
              @click="closeModal"
            >
              Cancel
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';

import useMaskedMoneyInput from './useMaskedMoneyInput';
import MerchantList from '../BTBFilterForm/MerchantList.vue';

import initializeFlagsmith from '../flagsmith';

const CENT_RAW_VALUE_MODIFIER = 100;

export default {
    async setup() {
        const {
            transactions: {
                addTransaction,
                fetchTransactions,
                getAuthorizedAmount,
                createDebounce,
            },
        } = inject('btb_transactions');

        const isFirstModalOpen = ref(false);
        const isSecondModalOpen = ref(false);
        const newTransaction = ref({});
        const isLoading = ref(false);
        const showNotification = ref(false);
        const notificationContent = ref('Cannot find merchant');
        const selectedId = ref('');
        const selectedIdSecond = ref('');

        const authorizedAmountRef = ref(0);

        const setMerchantId = () => {
            newTransaction.value.merchantId = selectedId.value.selected;
        };

        const openFirstModal = () => {
            isFirstModalOpen.value = true;
        };

        const openSecondModal = () => {
            selectedIdSecond.value.selected = newTransaction.value.merchantId;
            isSecondModalOpen.value = true;
        };

        const reinitialize = () => {
            newTransaction.value.name = '';
            newTransaction.value.surname = '';
            newTransaction.value.email = '';
            newTransaction.value.authorizedAmount = '';
            newTransaction.value.settledAmount = '';
            newTransaction.value.nonce = '';
        };

        const closeModal = () => {
            selectedId.value.selected = '';
            reinitialize();
            isSecondModalOpen.value = false;
            isFirstModalOpen.value = false;
        };

        const isFirstSaveButtonActive = () => !newTransaction.value.merchantId;

        const isSaveButtonActive = () => {
            const requiredFields = ['name', 'surname', 'email', 'authorizedAmount', 'settledAmount', 'merchantId', 'nonce'];
            return !requiredFields.every((keyName) => newTransaction.value[keyName]);
        };

        const camelToSnake = ({
            name,
            surname,
            email,
            authorizedAmount,
            settledAmount,
            merchantId,
            nonce,
        }) => ({
            name,
            surname,
            email,
            authorized_amount: authorizedAmount,
            settled_amount: settledAmount,
            merchant_id: merchantId,
            nonce,
        });

        const debounce = createDebounce();

        const setAuthorizedAmountWithDebounce = async () => {
            debounce(async () => {
                await getAuthorizedAmount(
                    newTransaction.value.settledAmount,
                    newTransaction.value.merchantId,
                ).then((response) => {
                    newTransaction.value.authorizedAmount = (response.authorized_amount
                    / CENT_RAW_VALUE_MODIFIER).toLocaleString('en-US');
                    authorizedAmountRef.value = response.authorized_amount;
                });
            });
        };

        const setAuthorizedAmount = async () => {
            await getAuthorizedAmount(
                newTransaction.value.settledAmount,
                newTransaction.value.merchantId,
            ).then((response) => {
                newTransaction.value.authorizedAmount = (response.authorized_amount
                    / CENT_RAW_VALUE_MODIFIER).toLocaleString('en-US');

                authorizedAmountRef.value = response.authorized_amount;
            });
        };

        const addNewTransaction = () => {
            isLoading.value = true;

            setAuthorizedAmount()
                .then(() => addTransaction(camelToSnake({
                    ...newTransaction.value,
                    authorizedAmount: authorizedAmountRef.value,
                })))
                .then(() => {
                    showNotification.value = false;
                    isLoading.value = false;
                    reinitialize();
                    closeModal();
                })
                .catch(async (resp) => {
                    const data = await resp.json();
                    isLoading.value = false;
                    showNotification.value = true;
                    notificationContent.value = data.error;
                });
        };

        const { inputRef: settledAmountRef } = useMaskedMoneyInput({
            initialValue: newTransaction.value.settledAmount,
            onChange(value) {
                newTransaction.value.settledAmount = value;
            },
        });

        const flagsmith = await initializeFlagsmith();

        const shouldShowMaskedAmountInputs = flagsmith.hasFeature('btb_masked_amount_inputs');

        return {
            addNewTransaction,
            closeModal,
            fetchTransactions,
            isLoading,
            isFirstModalOpen,
            isSecondModalOpen,
            isFirstSaveButtonActive,
            isSaveButtonActive,
            newTransaction,
            openFirstModal,
            openSecondModal,
            reinitialize,
            settledAmountRef,
            shouldShowMaskedAmountInputs,
            showNotification,
            MerchantList,
            notificationContent,
            getAuthorizedAmount,
            setAuthorizedAmount,
            selectedId,
            selectedIdSecond,
            setMerchantId,
            setAuthorizedAmountWithDebounce,
        };
    },
};
</script>
