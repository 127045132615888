<template>
  <div class="mb-5">
    <AddTransaction v-if="shouldDisplayAddTransactionButton" />
    <AddRefund v-if="shouldDisplayRefund" />
  </div>
  <div class="table-container">
    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <OrderableTableHeader
        :accounting-user="isAccountingUser()"
      />
      <tbody v-if="transactions.length > 0">
        <TransactionRow
          v-for="(transaction, index) in transactions"
          :key="index"
          :transaction="transaction"
          :edit="editTransaction"
          :accounting-user="isAccountingUser()"
        />
      </tbody>
      <tbody v-else>
        <tr>
          <td
            class="has-text-centered is-size-7"
            colspan="100"
          >
            No transactions found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Pagination
    v-if="!isPaginationDisable && maxPage > 1"
    :max-page="maxPage"
  />
  <SelectMerchant
    v-if="isOpenModal"
    :transaction="editedTransaction"
    :merchant="merchant"
    :open-edit="openEdit"
    @closeModal="closeModal"
  />
  <EditTransaction
    v-if="isEditOpen"
    :transaction="editedTransaction"
    :merchant-id="selectedMerchantId"
    @closeModal="closeModal"
  />
  <div class="is-flex is-justify-content-space-between">
    <DownloadCsvButton :disabled="transactions.length === 0" />
  </div>
</template>

<script>
import { inject, ref } from 'vue';

import initializeFlagsmith from '../flagsmith';
import OrderableTableHeader from './OrderableTableHeader.vue';
import TransactionRow from './TransactionRow.vue';
import EditTransaction from './EditTransaction.vue';
import SelectMerchant from './SelectMerchant.vue';
import DownloadCsvButton from './DownloadCsvButton.vue';
import AddTransaction from './AddTransaction.vue';
import Pagination from './Pagination.vue';
import AddRefund from './AddRefund.vue';

export default {
    components: {
        OrderableTableHeader,
        TransactionRow,
        EditTransaction,
        SelectMerchant,
        DownloadCsvButton,
        AddTransaction,
        Pagination,
        AddRefund,
    },

    async setup() {
        const isOpenModal = ref(false);
        const editedTransaction = ref([]);
        const isEditOpen = ref(false);
        const selectedMerchantId = ref('');
        const merchant = ref('');

        const mockedTransactions = [{
            firstName: 'Kamil',
            surname: 'Kominek',
            transactionId: '1',
            email: 'kamil.kominek@makimo.pl',
            nonce: 'Nonce',
            bankName: 'Makimo',
            ipAddress: '192.169.1.1',
            timestamp: new Date(),
            currency: 'USD',
            merchantId: '1',
            mid: '1',
            status: 'CREATED',
            receivedBy: 'kkominek',
            authorizedAmount: 4000,
            targetCurrency: 'EUR',
            enteredAmount: 10,
            bankCode: '1',
            branchCode: 'AWW',
            companyName: 'Makimo',
            creation: 'MODIFIED',
        },
        {
            firstName: 'Dominik',
            surname: 'Abec',
            transactionId: '2',
            email: 'dominikk@makimo.pl',
            nonce: 'NonceTest',
            bankName: 'Makimo',
            ipAddress: '192.168.2.2',
            timestamp: new Date(),
            currency: 'USD',
            merchantId: '2',
            mid: '2',
            status: 'CREATED',
            receivedBy: 'dominikabecee',
            authorizedAmount: 4000,
            targetCurrency: 'EUR',
            enteredAmount: 10,
            bankCode: '1',
            branchCode: 'AWW',
            companyName: 'Makimo',
            creation: 'SYSTEM',
        },
        {
            firstName: 'Jan',
            surname: 'Nowak',
            transactionId: '0915b189-6f0b-4571-94db-6e6d7ae2b375',
            email: 'kamil.kominek@makimo.pl',
            nonce: 'NonceTest',
            bankName: 'Makimo',
            ipAddress: '192.169.1.1',
            timestamp: new Date(),
            currency: 'USD',
            merchantId: '1',
            mid: '1',
            status: 'CREATED',
            receivedBy: 'janN',
            authorizedAmount: 4000,
            targetCurrency: 'EUR',
            enteredAmount: 10,
            bankCode: '1',
            branchCode: 'AWW',
            companyName: 'Makimo',
            creation: 'MANUAL',
        }];

        const {
            transactions: {
                maxPage,
                transactions,
                fetchTransactions,
                isAccountingUser,
            },
        } = inject('btb_transactions');

        const flagsmith = await initializeFlagsmith();

        const shouldDisplayAddTransactionButton = flagsmith.hasFeature('btb_add_form');
        const isMockTransactionsDisable = flagsmith.hasFeature('use_btb_mock_transactions');
        const isPaginationDisable = !flagsmith.hasFeature('btb_pagination');
        const shouldDisplayRefund = flagsmith.hasFeature('btb_refund_form');

        if (!isMockTransactionsDisable) {
            fetchTransactions();
        } else {
            transactions.value = mockedTransactions;
        }

        const editTransaction = (transaction) => {
            if (isOpenModal.value === false) {
                editedTransaction.value = transaction;
                const merchantName = transaction.merchantId.split(' ')[0];
                const merchantId = flagsmith
                    .getValue('merchants_list')
                    .split(' ')
                    .filter((element) => element.endsWith(merchantName))[0]
                    .split(',')[0];
                merchant.value = merchantId;
                isOpenModal.value = true;
            }
        };

        const openEdit = (transaction, merchantId) => {
            if (isEditOpen.value === false) {
                editedTransaction.value = transaction;
                selectedMerchantId.value = merchantId;
                isEditOpen.value = true;
            }
        };

        const closeModal = () => {
            isOpenModal.value = false;
            isEditOpen.value = false;
        };
        return {
            transactions,
            isEditOpen,
            editTransaction,
            openEdit,
            isPaginationDisable,
            maxPage,
            closeModal,
            isOpenModal,
            editedTransaction,
            shouldDisplayAddTransactionButton,
            shouldDisplayRefund,
            merchant,
            selectedMerchantId,
            isAccountingUser,
        };
    },
};
</script>
