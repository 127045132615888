<template>
  <Suspense>
    <NavBar />
  </Suspense>
  <div class="hero">
    <div class="section">
      <router-view />
    </div>
  </div>
</template>

<script>
import { provide } from 'vue';

import btbTransactions from '@/btb_transactions';
import NavBar from '@/main/NavBar.vue';

export default {
    components: {
        NavBar,
    },

    setup() {
        provide('btb_transactions', btbTransactions());
    },
};
</script>
