<template>
  <div class="box">
    <BTBFilterForm />
    <BTBTransactionList />
  </div>
</template>

<script>
import { inject } from 'vue';

import BTBFilterForm from '@/BTBFilterForm/Index.vue';
import BTBTransactionList from '@/BTBTransactionList/Index.vue';

const userInfoUrl = `${process.env.VUE_APP_COGNITO_DOMAIN_URL}/oauth2/userInfo`;

export default {
    components: {
        BTBFilterForm,
        BTBTransactionList,
    },

    async mounted() {
        const {
            transactions: {
                userProfile,
            },
        } = inject('btb_transactions');

        const headers = new Headers({
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        });

        const response = await fetch(userInfoUrl, { headers });

        if (response.ok) {
            const data = await response.json();
            userProfile.value = data.profile;
        }
    },
};
</script>
