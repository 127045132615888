<template>
  <nav
    class="navbar is-primary"
    role="navigation"
  >
    <div class="navbar-brand">
      <router-link
        class="navbar-item"
        :to="{ name: 'BankToBankTransactions' }"
      >
        BTB PAYIN-SEMI Transactions
      </router-link>
    </div>
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a
            class="button is-primary"
            @click="logoutAndRedirect"
          >
            <strong>Log out</strong>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { logoutAndRedirect } from '@/authentication';
import initializeFlagsmith from '../flagsmith';

export default {
    name: 'NavBar',

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const showNewIpView = flagsmith.hasFeature('new_ip_blacklist_view');
        const showMerchantLockout = flagsmith.hasFeature('locking_merchant_endpoint');
        const showBTBTransactions = flagsmith.hasFeature('btb_admin_panel');

        return {
            logoutAndRedirect,
            showNewIpView,
            showMerchantLockout,
            showBTBTransactions,
        };
    },
};
</script>
