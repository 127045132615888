<template>
  <button
    class="button is-small"
    style="background-color: #00d1b2; color: white;"
    @click="openModal()"
  >
    Receive
  </button>
  <div
    class="modal"
    :class="{ 'is-active': isModalActive }"
  >
    <div
      class="modal-background"
      @click="closeModal"
    />
    <div class="modal-content">
      <div class="box">
        <p class="is-5 has-text-centered">
          Are you sure to mark this transaction as received?
        </p>
        <div class="buttons is-centered mt-3">
          <button
            class="button is-success mx-4"
            @click="receiveTransaction"
          >
            Yes <i class="fas fa-check-circle ml-1" />
          </button>
          <button
            class="button is-danger mx-4"
            @click="closeModal"
          >
            No <i class="fas fa-times-circle ml-1" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';

export default {
    props: {
        transactionId: {
            type: String,
            required: true,
        },
    },

    setup(props) {
        const {
            transactions: {
                postReceive,
                markTransactionAsReceived,
            },
        } = inject('btb_transactions');

        const isModalActive = ref(false);

        const openModal = () => {
            isModalActive.value = true;
        };

        const closeModal = () => {
            isModalActive.value = false;
        };

        const receiveTransaction = async () => {
            closeModal();

            const request = await postReceive(props.transactionId);

            if (request instanceof Error) {
                throw request;
            } else {
                markTransactionAsReceived(props.transactionId);
            }
        };

        return {
            isModalActive,
            openModal,
            closeModal,
            receiveTransaction,
        };
    },
};
</script>
