<template>
  <div>
    <div class="select is-fullwidth">
      <div class="control is-expanded">
        <select
          @change="$emit('update:status', $event.target.value)"
        >
          <option
            v-if="showAllOption"
            value=""
            :selected="!status"
          >
            All
          </option>
          <option
            v-else
            value=""
            selected
            disabled
          >
            Select merchant
          </option>
          <option
            v-for="merchant in merchants"
            :key="merchant[0]"
            :value="merchant[0]"
          >
            {{ merchant[1] }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import initializeFlagsmith from '../flagsmith';

export default {
    props: {
        showAllOption: {
            required: false,
            default: false,
            type: Boolean,
        },
        status: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: ['update:status'],

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const merchants = flagsmith
            .getValue('merchants_list')
            .split(' ')
            .map((merchant) => merchant.split(','));

        return {
            merchants,
        };
    },
};
</script>
