import { onMounted, onBeforeUnmount, ref } from 'vue';
import Cleave from 'cleave.js';

const CENT_RAW_VALUE_MODIFIER = 100;

export default function useMaskedMoneyInput({ initialValue, onChange }) {
    const inputRef = ref(null);
    const inputMask = ref(null);

    onMounted(() => {
        if (!inputRef.value) return;
        inputMask.value = new Cleave(inputRef.value, {
            numeral: true,
            numeralPositiveOnly: true,
            onValueChanged({ target }) {
                onChange(target.rawValue * CENT_RAW_VALUE_MODIFIER);
            },
        });

        inputMask.value.setRawValue(initialValue / CENT_RAW_VALUE_MODIFIER);
    });

    onBeforeUnmount(() => {
        inputMask.value?.destroy();
    });

    return {
        inputRef,
    };
}
